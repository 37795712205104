import React, { memo, useState, useEffect } from "react";
import _ from "lodash";
import styled from "styled-components";
import axios from "axios";
import { gateway } from "../../utils/api";

import CardMyCourse from "./CardMyCourse";
import CustomContentLoader from "../Home/CustomContentLoader";

const WrapMyCourses = styled.div`
  overflow-x: hidden;
  min-height: 62.3vh;
`;

const MyCoursesTitle = styled.h6`
  font-size: 1.65em;
  padding: 10px;
`;

const MyCoursesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  padding: 10px;
`;

const fetchCourses = async (url, setCourses, setLoading, source) => {
  setLoading(true);
  try {
    const response = await gateway.get(url, { cancelToken: source.token });
    setCourses(_.get(response, "docs", []));
  } catch (error) {
    console.log(error.response);
  } finally {
    setLoading(false);
  }
};

const CoursesLane = ({ title, endpoint }) => {
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(true);
  const { CancelToken } = axios;
  const source = CancelToken.source();

  useEffect(() => {
    fetchCourses(endpoint, setCourses, setLoading, source);
    return () => {
      source.cancel("Cancelado por troca de rota");
    };
  }, [endpoint]);

  if (loading) {
    return <CustomContentLoader tooltipText={`Acesse ${title.toLowerCase()}`} title={`Carregando ${title}`} />;
  }

  return (
    <WrapMyCourses>
      <MyCoursesTitle>{title}</MyCoursesTitle>
      <MyCoursesContainer>
        {courses.length === 0 ? (
          <h5>Nenhum curso encontrado para exibição</h5>
        ) : (
          courses.map((course) => (
            <CardMyCourse key={`meu-curso-${course.idCourse}`} id={course.idCourse} nome={course.curso} course={course} />
          ))
        )}
      </MyCoursesContainer>
    </WrapMyCourses>
  );
};

const LaneNewCourses = () => {
  return (
    <>
      <CoursesLane title="Meus Cursos e Trilhas em Andamento" endpoint="webbff/lit_app_web/home/lane/type/LANE_IN_PROGRESS" />
      <CoursesLane title="Meus Cursos e Trilhas Concluídos" endpoint="webbff/lit_app_web/home/lane/type/LANE_COMPLETED" />
    </>
  );
};

export default memo(LaneNewCourses);
