import React, { useMemo, useState } from 'react';
import _ from 'lodash';
import adyen from 'adyen-cse-js';
import swal from "@sweetalert/with-react";
import Form from './Form';
import api, { gateway } from '../../../../utils/api';

import createUserTerm from '../../helpers/createUserTerm';
import extractTermTemplateReference from '../../helpers/extractTermTemplateReference';
import handleRequestErrorMessage from '../../helpers/handleRequestErrorMessage';
import handleFormSubmitHelper from '../../helpers/handleFormSubmit';
import { createVindiCustomer, encryptCreditCard } from '../../../../utils/vindi';

function CreditCardForm({ selectedPlan, onPaymentSuccess, couponCode }) {
  const [loading, setLoading] = useState(false);
  const [generatingTerm, setGeneratingTerm] = useState(false);
  const [alreadyGeneratedTerm, setAlreadyGeneratedTerm] = useState(false);
  const [userTerm, setUserTerm] = useState(null);
  const [showTerm, setShowTerm] = useState(false);

  const termTemplateReference = useMemo(
    () =>
      extractTermTemplateReference({
        selectedPlan,
        paymentMethod: 'CREDIT'
      }),

    [selectedPlan]
  );

  async function getServerTime() {
    try {
      return api.get('getServerTime');
    } catch (error) {
      return null;
    }
  }

  async function handleSubmitPayment(formValues) {
    try {
      setLoading(true);
      const creditCardNumber = formValues.number.replace(/\D/g, '');

      const paymentRequestBody = {
        crCodeCoupon: couponCode,
        type: 'CREDIT',
        offerReference: selectedPlan.code,
        nomeCompleto: formValues.holderName,
        cpf: formValues.document.replace(/\D/g, ''),
        dataNascimento: formValues.dateOfBirth.format('DD/MM/YYYY'),
        binCard: creditCardNumber.substring(0, 6),
        numero: creditCardNumber.substr(creditCardNumber.length - 4),
        tokenGateway: formValues.tokenGateway,
        installments: formValues.installments,
        term: userTerm
          ? {
            mediaCode: _.get(userTerm, 'ox_standard.ox_identification.cr_code'),
            templateCode: termTemplateReference
          }
          : null,
        sellerCode: localStorage.getItem('sellerCode'),
        sellerLinkId: localStorage.getItem('sellerLinkId'),
      };

      localStorage.removeItem('pixData');

      const url = selectedPlan.alreadyPurchased
        ? '/contract/contract/updatePaymentMethodVindi'
        : '/contract/contract/processSubscription';
      const method = selectedPlan.alreadyPurchased ? 'put' : 'post';
      const paymentResponse = await gateway[method](url, paymentRequestBody);
      const transactionCode = _.get(
        paymentResponse,
        'transactions.0.ox_standard.ox_identification.cr_code',
        null
      );

      console.log("paymentResponse", paymentResponse)
      console.log("transactionCode", transactionCode)

      onPaymentSuccess({
        selectedPlan,
        paidValue: _.get(
          paymentResponse,
          'captures.0.ox_specific.ox_capturevalues.ox_capturevalue.nf_value',
          0
        ),
        paymentResult: { ...paymentResponse.paymentInfo, transactionCode },
        paymentMethod: 'CREDIT',
        gatewayReference: _.get(
          paymentResponse,
          'captures.0.ox_specific.rc_gatewaycapturereference',
          'NONE'
        ),
        contractCode: _.get(
          paymentResponse,
          'contract.ox_standard.ox_identification.cr_code',
          ''
        ),
        alreadyPurchased: selectedPlan.alreadyPurchased
      });
    } catch (error) {
      handleRequestErrorMessage(error);
    } finally {
      setLoading(false);
    }
  }


  async function addCustomerCreditCard(data) {
    const url = '/contract/contract/addCustomerCreditCard'
    await gateway.post(url, data)
  }

  async function handleFormSubmit(values) {
    try {
      setLoading(true);
      const valuesCopy = { ...values };
      const creditCardNumber = valuesCopy.number.replace(/\D/g, '');
      const cpf = valuesCopy.document.replace(/\D/g, '');
      const createCustomerBody = {
        name: valuesCopy.holderName.trim(),
        cpf,
        paymentMethod: 'CREDIT',
      }
      const customer = await createVindiCustomer(createCustomerBody).catch(() => {
        throw new Error('Não foi possível processar o pagamento. Por favor, verifique os dados preenchidos!');
      });
      const encryptCreditCardData = {
        "holder_name": values.holderName,
        "card_expiration": `${values.expiryMonth}/${values.expiryYear}`,
        "card_number": creditCardNumber,
        "card_cvv": values.cvc,
        "customer_id": customer.idCustomer
      }
      const tokenGateway = await encryptCreditCard(encryptCreditCardData)
      const idProfile = localStorage.getItem('idProfile');
      const bodyCustomerCreditCard = {
        "name": values.holderName,
        "lastFourNumberOfCard": creditCardNumber.substr(creditCardNumber.length - 4),
        "cvv": values.cvc,
        "profileId": Number(idProfile),
        "customerIdVindi": customer.idCustomer,
        "token": tokenGateway,
      }
      addCustomerCreditCard(bodyCustomerCreditCard).catch(() => null);
      await handleSubmitPayment({ ...values, tokenGateway });
    } catch (error) {
      swal({
        title: `Ops, ocorreu um erro.`,
        text: error.message
      });
    } finally {
      setLoading(false);
    }
  }

  async function handleGenerateTerm(values) {
    try {
      if (alreadyGeneratedTerm) {
        setShowTerm(true);
        return;
      }

      setGeneratingTerm(true);
      const response = await gateway.post(
        '/webbff/lit_app_web/term/generatePaymentTerm',
        {
          termTemplateCode: termTemplateReference,
          offerCode: selectedPlan.code
        }
      ).catch(() => null);

      if (!response) {
        setUserTerm(null);
        setShowTerm(false);
        setAlreadyGeneratedTerm(false);
        setGeneratingTerm(false);
        handleFormSubmit(values);
        return;
      }

      setUserTerm(response);
      setShowTerm(true);
      setAlreadyGeneratedTerm(true);
    } catch (error) {
      console.log(error);
      setUserTerm(null);
      setShowTerm(false);
      setAlreadyGeneratedTerm(false);
      setGeneratingTerm(false);
      handleFormSubmit(values);
    } finally {
      setGeneratingTerm(false);
    }
  }

  async function handleOpenTerm() {
    try {
      setGeneratingTerm(true);
      if (!userTerm) {
        const response = await createUserTerm({
          termTemplateCode: termTemplateReference,
          offerCode: selectedPlan.code
        });
        setUserTerm(response);
      }
    } catch (error) {
      handleRequestErrorMessage(error);
    } finally {
      setGeneratingTerm(false);
    }
  }

  return (
    <Form
      handleGenerateTerm={handleGenerateTerm}
      onSubmit={formValues =>
        handleFormSubmitHelper({
          selectedPlan,
          processPaymentFunction: handleFormSubmit,
          formValues
        })
      }
      termTemplateReference={termTemplateReference}
      selectedPlan={selectedPlan}
      userTerm={userTerm}
      onOpenTerm={handleOpenTerm}
      generatingTerm={generatingTerm}
      showTerm={showTerm}
      setShowTerm={setShowTerm}
      loading={loading}
    />
  );
}

export default CreditCardForm;
