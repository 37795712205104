import React from "react"
import Navbar from "./Navbar"
import imagens from "../../utils/imagens";
import history from "../../utils/history";
import { BLUE_LIT } from "../../utils/colors";

function TermosExcluirConta() {

  return (
    <div>
      <Navbar bgCollor="rgb(2,196,255)" />
      <div className="row justify-content-center">
        <div className="col-6">
          <h2 style={{ marginBottom: 40 }}>Instruções para exclusão de conta</h2>

          <h3 style={{ margin: '20px 0' }}>1 - Acesse sua conta e clique na imagem no canto superior direito, após abrir o menu, clique em "Fale conosco" superior direito, como na imagem abaixo.</h3>
          <p>Faça seu login, <a style={{ color: BLUE_LIT }} href="javascript:void(0)" onClick={() => history.push('login')}>clicando neste link</a>.</p>
          <img src={imagens.print1account} height={450} style={{ marginBottom: 50 }} />

          <h3 style={{ margin: '20px 0' }}>2 - Clique no botão "Enviar minha pergunta".</h3>
          <img src={imagens.print2account} height={450} style={{ marginBottom: 50 }} />

          <h3 style={{ margin: '20px 0' }}>3 - No campo "Assunto", selecione a opção "Excluir minha conta".</h3>
          <img src={imagens.print3account} height={450} style={{ marginBottom: 50 }} />

          <p>Pronto, agora só aguardar a confirmação de exlusão dos dados e conta.</p>

          <p style={{ marginBottom: 80 }}><a style={{ color: BLUE_LIT }} href="javascript:void(0)" onClick={() => history.push('home')}>Ir para o LIT</a>.</p>


        </div>
      </div>
    </div>
  )
}

export default TermosExcluirConta; 
