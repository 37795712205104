import React, { useState, useEffect } from 'react';
import _ from 'lodash';

import { gateway } from '../../../utils/api';

const NavBar = () => {
  const [settings, setSettings] = useState(null);
  const [tenant, setTenant] = useState('');
  const [loading, setLoading] = useState(true);
  async function fetchSettings(savedTenant) {
    try {
      const response = await gateway.get(
        'webbff/lit_app_web/settings/white-label'
      );
      setSettings(response);
      setTenant(savedTenant);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }
  useEffect(() => {
    const savedSettings = JSON.parse(localStorage.getItem('settings'));
    const savedTenant = localStorage.getItem('tenant') || '';

    if (!savedSettings) {
      fetchSettings(savedTenant);
    } else {
      setSettings(savedSettings);
      setTenant(savedTenant);
      setLoading(false);
    }
  }, []);

  if (loading) {
    return <div>laoding</div>;
  }
  console.log(settings, 'settings');
  const logoByTenant = tenant ? _.get(settings[tenant], 'logoUrl', '') : '';
  const { headerBackgroundColor, headerBackgroundGradient } = _.get(
    settings,
    tenant,
    {}
  );

  return (
    <div
      style={{
        background: headerBackgroundColor || headerBackgroundGradient,
        textAlign: 'center',
        padding: '8px'
      }}
    >
      <a
        className="navbar-brand"
        style={{
          backgroundColor: headerBackgroundColor,
          minWidth: 150,
          padding: '0rem 1rem'
        }}
      >
        <img
          src={logoByTenant}
          alt="Logo LIT"
          style={{
            maxWidth: '100%',
            height: 'auto',
            maxHeight: '45px',
            objectFit: 'contain'
          }}
        />
      </a>
    </div>
  );
};

export default NavBar;
